__webpack_public_path__ = `${document.documentElement.dataset.publicpath}`;
import 'what-input';
import Aos from 'aos';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ComponentDispatcher } from 'html-scoped-component-importer';
import smoothscroll from 'smoothscroll-polyfill';
import AnalyticsDataService from './services/analyticsDataService';
import ArnClientService from './services/ArnClientService';
import AppService from './services/appService';
import OverlayService from './services/overlayService';
import customEventBus from './libs/eventBus';
gsap.registerPlugin(ScrollTrigger);
window.eventQueue = new Map();
new AnalyticsDataService();
new ArnClientService();
new AppService();
new OverlayService();
smoothscroll.polyfill();
//FixVoChrome
(() => {
    let inputMode = '';
    let keyboradCheckingHandler = (e) => {
        document.removeEventListener('keydown', keyboradCheckingHandler);
        document.addEventListener('touchstart', pointerCheckHandler);
        document.addEventListener('pointermove', pointerCheckHandler);
        if (inputMode != 'keyboard') {
            inputMode = 'keyboard';
            document.body.classList.add('keyboard-mode');
            setTimeout(() => {
                customEventBus.emit('keyboard:mode', { isKeyboardMode: true, target: e.target });
            }, 0);
        }
    };
    let pointerCheckHandler = () => {
        document.addEventListener('keydown', keyboradCheckingHandler);
        document.removeEventListener('pointermove', pointerCheckHandler);
        document.removeEventListener('touchstart', pointerCheckHandler);
        if (inputMode != 'pointer') {
            inputMode = 'pointer';
            document.body.classList.remove('keyboard-mode');
            setTimeout(() => {
                customEventBus.emit('keyboard:mode', { isKeyboardMode: false });
            }, 0);
        }
    };
    document.body.classList.add('keyboard-mode');
    document.addEventListener('pointermove', pointerCheckHandler);
    document.addEventListener('touchstart', pointerCheckHandler);
})();
window.addEventListener('DOMContentLoaded', (event) => {
    let scenes = Array.from(document.querySelectorAll('.new-parallax-animations .parallax-element'));
    scenes.forEach((element, i) => {
        const sectionBg = element.querySelector('.video-wrapper') || element.querySelector('img');
        // sectionBg.style.transform = 'translateY(0px) scale(1.2)';
        gsap.to(sectionBg, {
            translateY: `${innerHeight / 2}px`,
            // scale: 1.2,
            ease: 'none',
            scrollTrigger: {
                trigger: element,
                start: 'top top',
                end: 'bottom top',
                scrub: true,
            },
        });
    });
    Aos.init({
        offset: 0,
        delay: 100,
    });
});
window.addEventListener('aos:refresh', () => {
    Aos.refresh();
});
document.addEventListener('lazybeforeunveil', function (e) {
    var _a, _b;
    const img = e.target;
    const parentElement = img.parentElement;
    const parentElementClasslist = parentElement.classList;
    if (parentElementClasslist.contains('product-image')) {
        parentElementClasslist.add('parent-lazyloaded');
    }
    if (img.classList.contains('hero-carousel-img') && parentElement.closest('.swiper-slide.swiper-slide-active')) {
        (_a = parentElement.closest('.hero-carousel')) === null || _a === void 0 ? void 0 : _a.classList.add('img-loaded');
    }
    if (img.classList.contains('hero-img')) {
        (_b = parentElement.closest('.hero')) === null || _b === void 0 ? void 0 : _b.classList.add('img-loaded');
    }
});
const compDispatcher = new ComponentDispatcher();
compDispatcher.createAsyncComponents();
// Can be used to load TS Components programmatically, cfr. componentsUtils
document.addEventListener('loadAsyncComponent', function (e) {
    compDispatcher.createAsyncComponents(e.detail);
});
